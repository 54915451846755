import type { IAppAuthDestination, IAppDestination } from "~/typings/app";

export default defineNuxtRouteMiddleware((to) => {
  const router = useRouter();
  const { getAccessTokenCookie } = useAuth({ skipLifeCycleHooks: true });
  const authDestination: Ref<IAppAuthDestination | null> = useState(STATE_AUTH_DESTINATION, () => null);
  if (!getAccessTokenCookie()) {
    const token = `t${Date.now()}`;
    const { params, query, name } = to;
    authDestination.value = { token, destination: { params, query, name } as IAppDestination };
    publish(PUBSUB_AUTHORIZE, token);
    // тут пока-что делаем через router,
    // т.к. navigateTo на SSR делает серверный редирект и он теряет инфу гидрации про authDestination
    return router.push(Routes.Main);
  }
});
